import { useAuth } from "./../util/auth";
import { useUserCredit } from "../util/db";
import { BoltIcon } from "@heroicons/react/24/solid";
import Link from "next/link";

function CreditBadge() {
  const auth = useAuth();
  const {
    data: credit,
    status: creditStatus,
    error: creditError,
  } = useUserCredit(auth.user && auth.user.uid);

  return (
    <>
      <Link href="/pricing">
        <div className="badge badge-secondary font-bold text-xs">
          <BoltIcon className="w-3 h-3 mr-1" />

          {creditStatus === "loading" && <>..</>}
          {creditStatus === "error" && <>!</>}
          {creditStatus === "success" && credit && <>{credit.credits}</>}
        </div>
      </Link>
    </>
  );
}

export default CreditBadge;
