import React, { useRef } from "react";
import Link from "next/link";
import { useAuth } from "./../util/auth";
import CreditBadge from "./CreditBadge";

import Image from "next/image";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";

function Navbar(props) {
  const auth = useAuth();
  const drawerRef = useRef();

  const closeDrawer = () => {
    drawerRef.current.checked = false;
  };

  return (
    <>
      <div className="drawer">
        <input
          id="nav-drawer"
          ref={drawerRef}
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content flex flex-col">
          <header>
            <div className="w-full navbar bg-base-300">
              <div className="flex-none lg:hidden">
                <label
                  htmlFor="nav-drawer"
                  className="btn btn-square btn-ghost"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="inline-block w-6 h-6 stroke-current"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>
                </label>
              </div>
              <div className="flex-1 px-2 mx-2">
                <Link
                  href="/"
                  shallow={true}
                  className="btn btn-ghost px-2 normal-case text-xl"
                >
                  <Image
                    src="/logo.svg"
                    alt="Elaborate Logo"
                    width={25}
                    height={25}
                    className="mr-2"
                  />
                  elaborate
                </Link>
              </div>
              <div className="flex-none hidden lg:block">
                {auth.user && (
                  <>
                    <CreditBadge />
                    <ul className="menu menu-horizontal px-1">
                      <li>
                        <Link
                          href="/new"
                          shallow={true}
                          className="btn btn-ghost ml-2"
                        >
                          New Story
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="/stories"
                          shallow={true}
                          className="btn btn-ghost ml-2"
                        >
                          Your Stories
                        </Link>
                      </li>
                    </ul>
                  </>
                )}

                {!auth.user && (
                  <ul className="menu menu-horizontal px-1">
                    <li>
                      <Link
                        href="/pricing"
                        shallow={true}
                        className="btn btn-ghost"
                      >
                        Pricing
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="/auth/signin"
                        shallow={true}
                        className="btn btn-ghost"
                      >
                        Sign in
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
              {auth.user && (
                <div className="flex-none hidden lg:block">
                  <div className="dropdown dropdown-end">
                    <button className="btn btn-ghost btn-square">
                      <EllipsisHorizontalIcon className="inline-block w-5 h-5 stroke-current" />
                    </button>
                    <ul className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-200 rounded-box w-52">
                      <li>
                        <Link
                          href="/settings/general"
                          shallow={true}
                          className="btn btn-ghost"
                        >
                          Settings
                        </Link>
                      </li>
                      <li>
                        <Link
                          href="/auth/signout"
                          shallow={true}
                          className="btn btn-ghost"
                          onClick={(e) => {
                            e.preventDefault();
                            auth.signout();
                          }}
                        >
                          Sign out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </header>

          {props.children}
        </div>

        <div className="drawer-side">
          <label htmlFor="nav-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 w-80 bg-base-100">
            <li>
              <Link
                href="/"
                shallow={true}
                className="normal-case text-xl font-bold"
                onClick={closeDrawer}
              >
                elaborate
              </Link>
            </li>

            {auth.user ? (
              <>
                <li>
                  <a onClick={closeDrawer}>
                    <CreditBadge />
                  </a>
                </li>
                <li>
                  <Link href="/new" shallow={true} onClick={closeDrawer}>
                    New Story
                  </Link>
                </li>
                <li>
                  <Link href="/stories" shallow={true} onClick={closeDrawer}>
                    Your Stories
                  </Link>
                </li>
                <li>
                  <Link
                    href="/settings/general"
                    shallow={true}
                    onClick={closeDrawer}
                  >
                    Settings
                  </Link>
                </li>
                <li>
                  <Link
                    href="/auth/signout"
                    shallow={true}
                    onClick={(e) => {
                      e.preventDefault();
                      auth.signout();
                    }}
                  >
                    Sign out
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link href="/pricing" shallow={true} onClick={closeDrawer}>
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link href="/auth/signin" shallow={true} onClick={closeDrawer}>
                    Sign in
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Navbar;
