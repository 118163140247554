import React, { useState, useEffect } from "react";
import "./../styles/global.css";
import Navbar from "./../components/Navbar";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { QueryClientProvider } from "./../util/db";
import Footer from "../components/Footer";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import Router from "next/router";
import setupCrisp from "./../util/crisp";

function App({ Component, pageProps }) {
  const [supabaseClient] = useState(() => createBrowserSupabaseClient());

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Set up Crisp
    setupCrisp();

    // Listen to the router events and set loading state
    const start = () => {
      setIsLoading(true);
    };
    const end = () => {
      setIsLoading(false);
    };

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);
    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  return (
    <SessionContextProvider
      supabaseClient={supabaseClient}
      initialSession={pageProps.initialSession}
    >
      <QueryClientProvider>
        <AuthProvider initialSession={pageProps.initialSession}>
          <>
            <Navbar>
              <Component isPageLoading={isLoading} {...pageProps} />
              <Footer />
            </Navbar>
          </>
        </AuthProvider>
      </QueryClientProvider>
    </SessionContextProvider>
  );
}

export default App;
